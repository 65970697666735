import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

function MtCookIndex(props) {
  return (
    <>
      <SEO title="Mt Cook Day Trips & Tours | Mt Cook New Zealand - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/mount-cook-lake.jpg")}
                alt=""
                class="first image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Mt Cook</h1>
                <h3>
                  Experience the awe-inspiring beauty of the Southern Alps
                </h3>
                <Link
                  class="alltours"
                  to="/home/new-zealand-tours/mt-cook-tours#tours-section"
                >
                  <p>View all Mt Cook tours &gt;</p>
                </Link>
              </div>
              <div class="destination-content">
                {/* ... */}
                <p>
                  New Zealand’s highest mountain,{" "}
                  <strong>
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki"
                      target="_blank"
                    >
                      Mt Cook
                    </Link>
                  </strong>{" "}
                  (<em>Aoraki</em> in Māori, meaning 'cloud piercer') towers
                  3,724 metres into the sky and provides a stunning backdrop to
                  the beautiful <strong>Aoraki/ Mt Cook National Park</strong>{" "}
                  in the centre of the South Island.
                </p>
                <p>
                  Declared a World Heritage park in 1986, it sits within the{" "}
                  <strong>Mackenzie Basin</strong> and boasts over thirty 3,000
                  metre high peaks, which make up the Southern Alps. It also has
                  five of New Zealand's largest glaciers, including the Tasman
                  Glacier at 21km in length. Scenic flights over Mt Cook
                  National Park provide unparalleled views of this spectacular
                  spot.
                </p>
                <p>
                  The <strong>Mt Cook Alpine Village&nbsp;</strong>is inside the
                  National Park. Here the iconic{" "}
                  <strong>
                    <a
                      href="http://www.hermitage.co.nz/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Hermitage Hotel
                    </a>
                  </strong>{" "}
                  offers a luxurious space in which to relax, have lunch and
                  soak up the atmosphere and the views.
                </p>
                <p>
                  The region is also renowned for its clear, starry nights and
                  in June 2012 it was designated a Dark Sky Reserve, recognizing
                  the clarity of the skies in the{" "}
                  <strong>Mackenzie Basin</strong>. It is the first Dark Sky
                  Reserve in the southern hemisphere and the world’s largest,
                  encompassing a 4300 sq km area.
                </p>
                <p>
                  Travelling south from Mt Cook, you'll journey through the
                  distinctive tawny tussock land of the Mackenzie Country, past
                  the vivid blue glacier-fed <strong>Lake Tekapo, </strong>
                  massive <strong>hydro-dams </strong>and onwards through the
                  township of Cromwell, known as "The Fruit Bowl of the South" –
                  keep an eye out for the giant fruit at its entrance!&nbsp;
                </p>
                <p>
                  <span>Mt Cook&nbsp;</span>Day bus tours depart daily from
                  Queenstown and Christchurch and can be completed as a return
                  day trip or one-way journey.&nbsp;
                </p>
                {/* ... */}
              </div>
              <div id="tours-section" class="row">
                <div class="tour-item six columns tag-tour first-tour">
                  <h3>Our most popular tour</h3>
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/thumbs/mtcook.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Mt Cook - Christchurch to Queenstown</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$129.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>

                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/thumbs/mtcook.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Mt Cook - Christchurch to Queenstown</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$129.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-return"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/thumbs/mtcook_zqn_return.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Mt Cook - Queenstown Return</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$99.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-return">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/thumbs/mtcook_zqn.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Mt Cook - Queenstown to Christchurch</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$99.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/thumbs/milford_chc_mt_cook.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Milford Sound Tour + Mt Cook Tour Combo</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$285.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MtCookIndex
